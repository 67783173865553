.seperator {
    width: 100%;
    height: 2px;
    background-color: #edeef3;
    border: none;
}

.menuitem {
    display: flex;
    text-align: center;
    flex-direction: row;
    text-decoration: none;
    padding-right: 90px;
    color: #4f5386;
    font-family: 'Metropolis-SemiBold';
}