.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  min-width: 175px;
  max-width: 350px;
  /* min-height: 55px; */
  /* max-height: 75px; */
  text-align: center;
  color: white;
  background-color: #020d89;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  padding: 12px 5px 12px 5px;
  font-family: 'Metropolis-Bold' !important;
}

.infoicon {
  padding-left: 5px;
}