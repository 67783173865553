.doublecontainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    border-radius: 10px;
    border: solid 1px #d4ddf0;
    background-color: #ffffff;
}

.section{
    flex-grow: 1;
    height: 100%;
}

.button{
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    cursor: pointer;
    color: #000d89;
    font-size: 12px;
}

.bottom{
    border-left:  solid 1px #d4ddf0;
}
