.container {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: 'auto';
    background-color: white;
    max-width:400px;
    padding: 20px 10px 10px 10px;
    box-sizing: border-box;
    row-gap: 10px;
    border-radius: 12px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 2px #bfc3de;
}

.header {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #000d89 !important;
    justify-content: space-between;
    align-items: center;
}

.name{

}

.content {
    display: flex;
    font-size: 12px;
    line-break: loose;
    color:#4f5386;
}

.buttons {
    margin-top: 10px;
    display: flex;
    column-gap: 10px;
    width: 100%;
    justify-content: space-evenly;
}
.disable{
    display: none;
}