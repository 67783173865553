.modal {
    z-index:101;
    opacity: 0;
    visibility: hidden;
    width: 0px;
    height: 0px;
  }

.active{
    opacity: 1;
    visibility: visible;
    position: absolute;
    transition: opacity 0.1s;
    color: #000;
    top:0;
    left:0;
}

.modalcontainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
}

.header{
  display: flex;
  height: 8vh;
  margin: 0 2%;
  min-height:8vh;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.separator{
  height: 0.3rem;
  flex-grow: 0;
  background-image: linear-gradient(to right, #01effd 0%, #010f8a 51%, #f601fb 100%);
}

.body{
  display : flex;
  flex-direction: column;
  align-items: center;
  -webkit-backdrop-filter: blur(45px);
  backdrop-filter: blur(45px);
  background-color: rgba(0, 6, 70, 0.66);
  width: 100%;
  height: 100%;
  color:white;
}

.bodycontent{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem 1rem 0 0;
  border: solid 1px rgba(226, 226, 234, 0.5);
  background-color: #f5f7f9;
  color: #000d89;
  padding:1rem;
  margin:.5rem;
}

.bannerarea{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: gray;
  border-radius: 0 0 1rem 1rem;
  margin: .5rem;
}
