.searchcontainer{
    display:flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f7f9;
    border-radius: 1rem;
    padding-left:10px;
    padding-right:10px;
}

.inputarea{
    flex-basis: 85%;
}

.input{
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
    width: 80%;
    background-color: #f5f7f9;
    border-radius: 1rem;
    border:none;

}

.logoarea{
    display: flex;
}