.forminfo{
  display:flex;
  width: 100%;
  font-size: 1.5rem;
  font-family: 'Metropolis-Bold';
  font-weight: bold;
  color: #000d89;
  padding: 1rem;
}

.switcharea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form {
  width: 100%;
  height: 100%;
}

.inputgroup{
  display : flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  row-gap:10px;
}