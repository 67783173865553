.input{
    display:flex;
    box-sizing: border-box;
    align-items: center;
    font-size: 1rem;
    border-radius: 10px;
    background-color: white;
    border: solid 1px #bfc3de;
    padding: 1rem;
    color: #4f5386;
}

.error{
    border-color: red !important;
}

.errortext{
    color:red !important;
    font-size: 11px !important;
    padding: 2px;
}