.pricetext{
    font-family: 'Metropolis-Bold';
}

.aftercomma{
    font-family: 'Metropolis-Medium';
    letter-spacing: 0.1rem;
}
.counttext{
    font-weight: 470;
}