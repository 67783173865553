.filteritem{
    padding: 2%;
    flex-basis: 30%;
}



.cards{
    display:flex;
    column-gap:20px;
    min-height: 200px;
}

.sides{
    display:flex;
    justify-content: space-between;
    flex-basis: 70%;
    column-gap:20px;
}

.leftside{
    display:flex;
    flex-direction: column;
    row-gap:20px;
    justify-content: space-around;
    align-items: center;
    flex-basis: 60%;
    border-radius: 16px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    padding: 20px;
    background-color:#fff;
}

.margintop{
    margin-top: 2%;
}
.textarea{
    display : flex;
    font-size: 1.5rem;
    justify-content: space-between;
    padding: 0px 16px;
}

.rightbottomtextarea{
    display : flex;
    font-size: 1.5rem;
    justify-content: space-between;
    padding:20px;
}

.headgroup{
    display : flex;
    flex-direction: column;
    width:100%;
}

.cardbg {
    background-image: url("../../../../assets/img_pttrn_little.png");
    background-repeat: no-repeat;
    background-position: right;
}

.header{
    font-size: 1.3rem;
    font-family: 'Metropolis-Bold';
    font-weight: bold;
    color: #000d89;
}


.headbtn{
    display : flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 16px;
    height: 1rem;
    width: 1rem;
    background-color: #f5f7f9;
    cursor: grab;
    text-decoration: none;
}

.seperator{ 
  width: 100%;
  height: 1px;
  flex-grow: 0;
  background-color: #edeef3;
}


.infoarea{
    display : flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    flex-basis: 47.5%;
    border-radius: 16px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
    background-color: #fff;
    font-size: 1rem;
}


.infoheader{
    color: #4f5386;
    display: flex;
    font-size: 1rem;
    line-height: 1.43;
    flex-direction: column;
    justify-content: center;
    padding: 0px 16px;
}

.bolddate{
    font-family: 'Metropolis-Bold';
}

.infobottom{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding : 20px;
}
.bolddate{
    font-weight: bold;
}

.listarea{
    display : flex;
    flex-direction: column;
    justify-content:center;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
    background-color: #fff;

}
.rightside{
    display:flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    flex-basis: 38.8%;
    border-radius: 16px;
    max-height: 47rem;
    row-gap: 20px;
}

.bannerarea{
 display : flex;
 justify-content: center;
 align-items: center;
 flex-basis: 50%;
 width: 100%;
 border-radius: 16px;
 box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
 border: solid 1px #edeef3;
 background-color : white;
}

.chartcontainer{
 /* display : flex; */
 flex-direction: column;
 /* flex-basis: 50%; */
 width: 100%;
 border-radius: 16px;
 /* flex-grow: 1; */
 box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
 background-color: #fff;

}

.chart{
    display : flex;
    flex-direction: column;
    margin: 16px;
    flex-basis: 30%;
}

.chartinfo{
    display : flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 70%;
    row-gap:20px;
}

.section{
    display : flex;
    flex-direction: column;
    justify-content: space-between;
    color: #4f5386;
    font-size: 1rem;
}

.bannerarea{
    display : flex;
    flex-direction: column;
}

.headsubtext{
    font-size: .9rem;
    color: #4f5386;
}


.textsection{
    display: flex;
    flex-direction: column;
    row-gap:10px;
}

.rightprice{
    font-size: 2rem;
    color: #000d89;
    font-weight: bold;
}
.rightcount{
    color: #4f5386
}

.counttext{
    color: #4f5386;
}
