.inputcontainer {
    display: flex;
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 1px #bfc3de;
    background-color: white;
    width: 100%;
    height: 100%;
}

.error {
    border-color: red !important;
}

.righterror {
    border-right-color: red !important;
}

.inputarea {
    display: flex;
    width: 100%
}

.input {
    width: 100%;
    font-size: 1rem;
    border: solid 1px transparent;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: solid 1px #bfc3de;
    padding-left: 1rem;
    padding-right: 1rem;
}

.iconarea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    cursor: pointer;
}

.searcharea {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    border: solid 1px #bfc3de;
    box-sizing: border-box;
}

.itemstop {
    display: flex;
    width: 100%;
    background: whitesmoke;
    border-bottom: solid 1px #c4c7dd;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-sizing: border-box; /* Ensure padding and border are included in width */
    overflow: hidden; /* Manage overflow */
}

.removeallbutton {
    display: flex;
    color: #000D89;
    border: solid 1px #bfc3de;
    border-radius: 8px;
    justify-content: space-around;
    align-items: center;
    padding: 8px 15px;
    font-family: "Metropolis-SemiBold" !important;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.selectingperson {
    font-size: 14px;
    text-decoration: solid;
    font-weight: bold;
    font-family: "Metropolis-Bold" !important;
    color: #4F5386;
    padding: 10px 15px;
    padding-left: 15px;
}
.inside{
    color: #000d89;
    width: 100%;

}

.emptyalert {
    display: flex;
    justify-self: center !important;
    align-self: center !important;
    padding: .2rem;
    font-weight: lighter;
}

.errortext {
    color: red !important;
    font-size: 11px !important;
    padding: 2px;
}