.label{
    color:#4f5386;
    font-size: .9rem;
}
.bold{
    font-family: 'Metropolis-Bold';
}

.detail{
    font-size: .8rem;
}

.success{
    color:#1ebcc8;
}

.failure{
    color:#ff5c5c;
}