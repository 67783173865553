.collapsecontainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2%;
}

.sendeddescription{
    margin-bottom: 20px;
}

.receiveddescription{
}

.text{
    margin-bottom: 10px;
    color: #4f5386;
    font-size: 1.3rem;
    font-weight: 600;
}