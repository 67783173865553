.itemarea{
    display : flex;
    border-radius: .5rem;
    justify-content:space-around;
    align-items: center;
    border-radius: 1rem;
    border: solid 0.15rem #edeef3;
    margin-top: 10px;
    min-height: 70px;
}

.bottomborderremove{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom: 0.15rem dashed  #edeef3 !important;
}

.itemcontainer{
    display : flex;
    justify-content:space-around;
    align-items: center;
    flex-basis: 100%;
}

.collapsebtn{
    display : flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: right;
    margin-left: 25px;
    width: 20%;
    text-align: center;    
}

.collapsedarea{
    display : flex;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: rgba(245, 247, 249, 0.5);
    border: solid 1px #edeef3;
    border-top: 0px;
    
}

.buttoncontainer{
    display : flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items: flex-end;
    flex-basis: 50%;
}

.close{
    display: none;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.item{
    display : flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: left;
    margin-left: 25px;
    width: 20%;
}

 .cell {
    border-top: 1px solid #edeef3;
    border-bottom: 1px solid #edeef3;
    height: 60px;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;

}

.firstcell {
    border-left: 1px solid #edeef3;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.lastcell {
    border-right: 1px solid #edeef3;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.collapsecell {
    border-right: 1px solid #edeef3;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}



.collapserow{
 position: relative;
 bottom: 16px;
}