  .element {
    background-color: #eee;
    padding: 15px;
    border-radius: 15px;
    position: relative;
  }
  .tooltip {
    position: absolute;
    background-color: #020d89;
    color: #e5e5e5;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 5px 10px -5px #060606;
    transform: translateX(-50%) scale(0);
    transform-origin: bottom;
    transition: transform 0.3s;
  }
  .tooltip::after {
    content: "";
    position: absolute;
    width: 10px;
    aspect-ratio: 1;
    background-color: #020d89;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .active {
    transform: translateX(-50%) scale(1);
  }