.leftside {
    display: flex;
    flex-direction: column;
    flex-basis: 60%;
    border-radius: 12px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    background-color: #fff;
    row-gap: 20px;
    padding: 20px;
}

.rightside {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 38.8%;
    border-radius: 12px;
    max-height: 500px;
    row-gap: 20px;
}

.margintop {
    margin-top: 2%;
}

.textarea {
    display: flex;
    font-size: 1.5rem;
    width: 96%;
    justify-content: space-between;
    align-items: center;
}

.headgroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.bg {
    background-image: url("../../../../../assets/img_pttrn_little.png");
    background-repeat: no-repeat;
    height: 250px;
    background-position: 90%;
}


.cardbg {
    height: 225px;
}

.header {
    font-size: 1.5rem;
    color: #1ebcc8;
    font-family: 'Metropolis-Bold';
}

.headbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 12px;
    height: 1rem;
    width: 1rem;
    background-color: #f5f7f9;
    cursor: grab;
    text-decoration: none;
}

.seperator {
    width: 95%;
    height: 1px;
    flex-grow: 0;
    background-color: #edeef3;
}


.infoarea {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 12px;

    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
    background-color: #fff;
    font-size: 1rem;
}


.infoheader {
    color: #4f5386;
    display: flex;
    line-height: 1.43;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.infobottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.bolddate {
    font-weight: bold;
}

.listarea {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    height: 100%;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
    background-color: #fff;

}

.section {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
    width: 100%;
    background-color: white;
}

.bannerarea {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
}

.inputarea {
    display: flex;
    justify-content: space-between;
    width: 95%;
}


.counttext {
    font-size: .9rem;
    color: #4f5386;
    font-family: 'Metropolis';
}