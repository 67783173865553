@font-face {
  font-family: "Metropolis";
  src: local("Metropolis"),
    url("./assets/Fonts/Metropolis-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Metropolis-Medium";
  src: local("Metropolis-Medium"),
    url("./assets/Fonts/Metropolis-Medium.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Metropolis-Light";
  src: local("Metropolis-Light"),
    url("./assets/Fonts/Metropolis-Light.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Metropolis-SemiBold";
  src: local("Metropolis-SemiBold"),
    url("./assets/Fonts/Metropolis-SemiBold.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Metropolis-Bold";
  src: local("Metropolis-Bold"),
    url("./assets/Fonts/Metropolis-Bold.otf") format("opentype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Metropolis';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9FBFC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body > #root {
  height: 100vh;
}

textarea:focus, input:focus{
  outline: none;
}