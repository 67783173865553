.container {
    display: flex;
    justify-content: space-between;
    margin: 0 2%;
    flex-grow: 1;
    
}

.content {
    background-color: #F9FBFC;
    height: 80%;
}