.container{
    display:flex;
    flex-direction:column;
    width: 100%;
    height: 100%;
    gap:10px;
}

.circlelogo{
    display:flex;
}

.circle{
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 50%;
    background-color:#ebebf5;
    width: 100px;
    height: 100px;
}

.infolabel{
    display:flex;
    flex-direction: column;
    flex-basis: 10%;
    row-gap: 10px;
    margin-top: 0.75rem;
}

.bold{
    font-weight: bold ;
}

.headlabel{
    font-weight: normal;
    font-size: 1.5rem;
}

.sublabel{
    color: #4f5386;
    font-weight: normal;
}

.table{
    display: flex;
    width: 100%;
    margin-top: 0.75rem;
}

.table span{
    font-size: 0.875rem;
}

.savelabel{
    display: flex;
    align-items:center;
    margin-top: 2%;
}

.button{
    margin-top: 2%;
}