.container {
    display: flex;
    height: 50px;
    margin: 15px 0 15px 0;
    /* background-color: white;
    border-bottom: 3px solid #e6e6e6; */
}

.content {
    flex-basis: 100%;
}

.button {
    display: flex;
    justify-content: center;
    background-image: "none";
    background-color: white;
}