.section {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-top: 1%;
    margin-bottom: 1%;
    min-height: 17rem;
    width: 30%;
    row-gap: 20px;
}

.secondsection{
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-right: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
    min-height: 17rem;
    width: 30%;
    row-gap: 20px;
}

.sectionheader {
    color: #4f5386;
    font-size: 1.1rem;
    font-family: 'Metropolis-Bold';
}

.item {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    height: 30%;
    padding: 15px 0;
}

.width {
    min-width: 175px;
}

.height{
    margin-top: -60%;
}

.indir {
    margin-right: 40px;
    margin-bottom: 1rem;
}

.sectionitem {
    margin-top: 2%;
}

.twicerow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.twiceleft {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    row-gap: 8px;

}

.twiceright {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-basis: 30%;
    min-width: 200px;
    row-gap: 8px;
}

.verticalseparator {
    margin-top: 1%;
    margin-bottom: 1%;
    width: 0px;
    border: 1px dashed #919eba;
    height: 80%;
}

.sectioncontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 100%;
}

.lastsection {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-right: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 33%;
    row-gap: 20px;
}

.test {
    display: flex;
    width: 100%;
    margin-top: 1rem;
}