.inputcontainer {
    display: flex;
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 1px #bfc3de;
    background-color: white;
    width: 100%;
    height: 100%;
}

.error {
    border-color: red !important;
}

.righterror {
    border-right-color: red !important;
}

.inputarea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
}

.input {
    display: flex;
    width: 100%;
    font-size: 1rem;
    border: solid 1px transparent;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: solid 1px #bfc3de;
    padding: 0.75rem;
}

.iconarea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 21%;
    padding-left: 15px ;
    cursor: pointer;
}

.searcharea {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: 'auto';
    background-color: white;
    border-radius: 10px;
    border: solid 1px #bfc3de;
    padding: .5rem;
    box-sizing: border-box;
}

.emptyalert {
    display: flex;
    justify-self: center !important;
    align-self: center !important;
    padding: .2rem;
    font-weight: lighter;
}

.errortext {
    color: red !important;
    font-size: 11px !important;
    padding: 2px;
}

.text{
    color: #4f5386;
    padding : 10px 0px;
    font-size:1.2rem;
    font-family: 'Metropolis-Bold';
}