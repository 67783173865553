.circlelogo {
    display: flex;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ebebf5;
    width: 100px;
    height: 100px;
}

.errorModalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 20rem;
    padding: 1rem;
}