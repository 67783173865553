.inputrange{
    display:flex;
    align-items: center;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    background-color: #f5f7f9;
    padding: 1rem;
    color: #4f5386;


}

.rangedropdown{
    display : flex;
    position: absolute;
    justify-content: space-around;
    align-items: center;
    border: solid 1px #bfc3de;
    border-radius: 10px;
    background-color:white;
    z-index: 1;
    height:4rem;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.first{
    display : flex;
    justify-content:center;
    flex-basis: 40%;
    margin: .3rem;
}

.second{
    margin: .3rem;
    display : flex;
    justify-content:center;
    flex-basis: 40%;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}