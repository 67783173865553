.tablecontainer{
    display: flex;
    flex-direction: row;
    min-height: 50px;
    border-bottom: 1px solid #bfc3de;
    border-left: 1px solid #bfc3de;
    border-right: 1px solid #bfc3de;
    color:#4f5386;
    font-size: 1.1rem;
}

.namearea{
    display : flex;
    align-items: center;
    border-right: 1px solid #bfc3de;
    padding-left: 5px;
    min-width: 208px;
    background-color: #f5f7f9;
}

@media (max-width: 768px) {
    .namearea{
        min-width: 120px !important;
    }
}

.valuearea{
    display : flex;
    align-items: center;
    flex-grow: 1;
    font-weight: 600;
    background-color: white;
}

.text{
    margin: 0 15px;
    width: 100%;
    
}

.first{
    border-top: 1px solid #bfc3de;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    
}

.last{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.firstname{
    
    border-top-left-radius: 20px;
}

.lastname{
    border-bottom-left-radius: 20px;
}

.firstvalue{
    
    border-top-right-radius: 20px;
}

.lastvalue{
    border-bottom-right-radius: 20px;
}