.textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 16px;
    resize: none;
    border-radius: 12px;
    border: solid 1px #bfc3de;
    font-family: 'Metropolis';
  }
  
textarea{
  font-family: 'Metropolis';
  font-weight: .8rem;
}
.textarea:focus { 
    outline: none !important;
}

.error{
  border-color: red !important;
}
