.card {
    display: flex;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border-radius: 16px;
    background-color: white;
}

.left {
    border-radius: 16px 0 0 16px;
    display: flex;
    flex-direction: column;
    flex-basis: 80%;
}

.lefttop {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin: 20px;
    flex-basis: 50%;
}

.headtext {
    font-size: 1.3rem;
    font-family: 'Metropolis-Bold';
    display: flex;
    align-items: center;
    justify-content: start;
}

.subtext {
    font-size: 0.9rem;
    max-width: 500px;
    color: #4f5386;
    letter-spacing: 1px;
}

.leftbottom {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin: 20px;
    flex-basis: 50%;
}

.right {
    border-radius: 0 16px 16px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-basis: 20%;
}

.pricetext {
    font-size: 2.6rem;
    font-weight: 800;
}

.aftercomma {
    font-size: 0.8rem;
    font-weight: 100;
    letter-spacing: 0.1rem;
}

.counttext {
    color: #4f5386;
    font-size: 0.9rem;
}

.btnNavigate {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 16px;
    height: 1rem;
    width: 1rem;
    margin: 20px;
    cursor: pointer;
}