.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
}


.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 100%;
    row-gap: 10px;
}

.formarea{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
}

@media (max-width: 768px) {
    .content{
        width: 100%;
    }

    .formarea{
        width: 90%;
    }
}


.headlogo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.formcontainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5%;
    border-radius: 10px;
    background-color: white;
}

.form{
    display: flex;
    flex-direction: column;
    background-color:#f5f7f9;
    margin: 10px;
    border: solid 1px rgba(226, 226, 234, 0.5);
    border-top-left-radius: 10px ;
    border-top-right-radius: 10px;
}

.formcontent{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin: 5%;
}

.infoarea{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: #000d89;
}

.infoname{
    font-size: 1.5rem;
}

.bold{
    font-weight: bold;
}

.tablearea{
    margin-top: 0.75rem;
    display: flex;
}

.tablearea span{
    font-size: 0.875rem !important;
}

.inputarea{
    display: flex;
    flex-direction: column;
    row-gap:10px;
    margin-top: 0.75rem;
}

.label{
    color: #000d89;
}

.inputs{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
}

.dropdowns{
    display: flex;
    column-gap: 10px;
}

.banner{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
    margin : 0 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.linkform{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .error{
    color: red !important;
    font-size: 11px !important;
    padding: 2px;
  }