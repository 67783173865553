.margintop{
    margin-top: 20px;
}

.marginbottom{
    margin-bottom: 20px;
}

.leftside{
    display:flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 60%;
}

.cardarea{
    display:flex;
    justify-content: space-between;
    width: 100%;
    height: 60%;
    column-gap: 20px;
}

.leftcards{
    display : flex;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: 49%;
    row-gap: 20px;
}

.rightcards{
    display : flex;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: 49%;
    row-gap: 20px;

}

.card{
    height: 48%;
}

.listarea{
    display : flex;
    flex-direction: column;
    justify-content:center;
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
    background-color: #fff;
    margin-top: 2%;
}

.leftcontent{
    display:flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 60%;
    border-radius: 3%;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    background-color: #fff;
}

.headgroup{
    display : flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}
.textarea{
    display : flex;
    font-size: 1.5rem;
    width: 96%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.header{
    font-size: 1.5rem;
    font-weight: bold;
    color: #000d89;
    font-family: 'Metropolis-Bold';
}

.headbtn{
    display : flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
    height: 1rem;
    width: 1rem;
    background-color: #f5f7f9;
    cursor: grab;
    text-decoration: none;
}

.seperator{ 
  width: 95%;
  height: 1px;
  flex-grow: 0;
  background-color: #edeef3;
}

.infoarea{
    display : flex;
    flex-direction: column;
    justify-content: space-around;
    width: 95%;
    border-radius: 1rem;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
    background-color: #fff;
    margin-top: 20px;
}

.infoheader{
    margin: 2%;
    color: #4f5386;
    display: flex;
    font-size: 1.3rem;
    line-height: 1.43;
    flex-direction: column;
    justify-content: center;
}

.bolddate{
    font-weight: bold;
}

.infobottom{
    margin-bottom: 2%;
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rightside{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 38.8%;
    border-radius: 3%;
    max-height: 47rem;
    max-width: 40%;
    background-color: white;
}

.rightcontent{
    display : flex;
    justify-content: center;
    align-items: center;
    width: 98%;
    height:98%;
    background-color: 
#f5f7f9;
    border-radius: 3%;
}

.formcontainer{
 display: flex;
 justify-content: space-between;
 width: 95%;
 height: 95%;
 flex-direction: column;
 
}

.customerarea{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: 20%;
}

.inputfield{
    flex-basis: 30%;
}

.inputgroup{
    display: flex;
    justify-content:space-between;
    flex-basis: 30%;
}

.groupitem{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 48%;
}

.transactionarea{
    display: flex;
    justify-content:space-between;
    flex-direction: column;
    width:100%;
    height: 10%;
}

.installmentarea{
    display: flex;
    flex-direction: column;
    width:100%;
    height: 20%;
}

.triplearea{
    display: flex;
    justify-content:space-between;
    align-items:center;
    flex-basis:48%;
}

.descriptionsubmit{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: 30%;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  /* LINK */

  .resultarea{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
  }

  .resulthead{
      display: flex;
      justify-content:flex-end;
  }

  .circle{
      background-color:#f5f7f9;
      padding:2rem;
      border-radius: 50%;
  }

  .name{
      font-size:2rem;
      color: #000d89;
  }

  .bold{
      font-weight:600;
  }

  .infotext{
      color:#4f5386;
      font-size:1.2rem;
  }

  .paymentarea{
      display: flex;
      flex-direction: column;
      row-gap: 10px;
  }

.headsubtext{
    font-size: .9rem;
    color: #4f5386;
}

.textsection{
    display: flex;
    flex-direction: column;
    row-gap:10px;
}