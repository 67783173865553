.filter {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-left: 5%;
}

.addbutton {
    display: flex;
    justify-content: flex-end;
    flex-basis: 20%;
}

.inputarea {
    display: flex;
    flex-direction: row;
    flex-basis: 80%;
}

.modal {
    padding-top: 15px;
    color: #000d89;
    display: flex;
    justify-content: center;
    background-color: #f5f7f9;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modaladd {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    color: #000d89;
    background-color: #f5f7f9;
    width: 100%;
    height: 100%;
    row-gap: 5px;
}