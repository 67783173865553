.sticky {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    bottom: 20px;
    width:60%;
    min-height: 78px;
    margin-bottom:50px;
    border-radius: 16px;
    background-image: linear-gradient(to right, #000d89 0%, #6608b7 100%);
    align-self: center;
    position: sticky;
    margin-top:277px;
  }

  .infolabel{
      margin-left: 20px;
  }

  .buttongroup{
      display: flex;
      align-items: center;
      justify-content:space-between;
      flex-basis: 70%;
      margin: 0 50px;
      font-size: 18px;
  }