.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.formitems {
    display: flex;
    flex-direction: column;
    padding: 15px;
    row-gap: 10px;
}

.container {
    background-color: #f4f4fb;
}

.filtercontainer {
    background-color: white;
}

.infoheader {
    color: #4f5386;
    display: flex;
    line-height: 1.43;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 20px 20px;
    font-family: 'Metropolis-Bold';
}

.infoarea {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 15px;
    line-height: 1.43;
    flex-basis: 47.5%;
    border-radius: 16px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
    background-color: #fff;
    font-size: 1rem;
}

.header {
    padding-left: 20px;
    font-family: 'Metropolis-Bold';
    font-size: 1.3rem;
    font-weight: bold;
    color: #000d89;
}

.header2 {
    font-family: 'Metropolis-Bold';
    font-size: 1.3rem;
    font-weight: bold;
    color: #000d89;
}

.infobottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.counttext {
    color: #4f5386;
    font-size: .9rem;
}

.bolddate {
    font-weight: bold;
}

.normaldate {
    font-family: Metropolis;
    font-weight: normal;
}

.listarea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
    background-color: #fff;
}

.section {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    border: solid 1px #edeef3;
    width: 100%;
    background-color: white;
}

.bannerarea {
    margin: 20px;
    min-height: 250px;
}

.paymentcollapse {
    min-width: 80%;
}

.customerarea {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
}

.subheaders {
    font-weight: bold;
    color: #000d89;

}

.inputfieldresponsive {
    display: flex;
    width: 100%;
    align-self: center;
}

.inputgroup {
    display: flex;
    justify-content: space-between;
    flex-basis: 30%;
    column-gap: 10px;
}

.groupitem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 48%;
}

.subtitle {
    align-self: center;
    color: #4f5386;
    font-family: "Metropolis";
    margin: 10px 33px 15px 20px;
    font-size: 12px;
}

.paymentmodal {
    display: flex;
    flex-direction: column;
    background-color: #edeef3;
    justify-content: center;
    align-items: center;
    padding: 10%;
    width: 90%;
    height: 100%;
    row-gap: 10px;
}