.biglabel {
    width: 190px;
    height: 18px;
    flex-grow: 0;
    margin: 0 47px 5px 0;
    font-family: Metropolis;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000d89;
}

.normallabel {
    flex-grow: 0;
    margin: 1px 5px 10px 0;
    font-family: Metropolis;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4f5386;
}

.info {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 10px;
}

.total{
    margin-left: 20px;
}