.buttonflipper{
    display: flex;
    width: 100%;
    transition: .8s cubic-bezier(.500, .07, .575, .500);
    transform-style: preserve-3d;
    border-radius: 10px;
    height:3rem;
 }

 .active{
        transform: rotateX(180deg);
 }
 
 .frontbutton{
    display: flex;
    align-items: center;
    width: 100%;
    background-color: white;
    border: solid 1px #bfc3de;
    backface-visibility: hidden;
    transform: rotateX(-180deg);
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    justify-content:center;
    color: #4f5386;
    font-weight: bold;
    font-size: 1rem;
 }

 .inputcontainer{
    display: flex;
    align-items: center;
    width:100%;
    height: 100%;
    border-radius: 10px;
    background-color: white;
    border: solid 1px #bfc3de;
    backface-visibility: hidden;
    z-index: 2;
    transform: rotateX(0deg);
    position: absolute;
 }

 .input{
    display:flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    font-size: 1rem;
    border-radius: 10px;
    padding: .9rem;
    color: #4f5386;
    border: none;
 }

 .icon{
     margin-right: 15px;
     cursor:pointer;
 }