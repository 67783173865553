.collapsecontainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2%;
    width: 100%;
}



.sendeddescription{
    margin-bottom: 20px;
}

.receiveddescription{
}

.text{
    margin-bottom: 10px;
    color: #4f5386;
    font-size: 0.8rem;
}
.data{
    font-family: "Metropolis";
    font-weight: bold;

    color: #4f5386;
    font-size: 0.8rem;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;

}