.btngroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  row-gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: #4f5386;
  font-size: 12px;
}

.dayweek {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;

}

.btn {
  flex-basis: 50%;
  border-radius: 12px;
  border: solid 1px #bfc3de;
  background-color: #fff;
  padding: 10px 0px;
  color: #4f5386;
  font-weight: bold;
  cursor: pointer;

}

.selectarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  color: #4f5386;
}

.navbtn {
  padding: 5px;
  border-radius: 50%;
  border: solid 1px #bfc3de;
  background-color: #fff;
  cursor: pointer;
}

.selected {
  background-image: linear-gradient(91deg, #000d89 1%, #6608b7 99%);
  color: white;
}

.datecontainer {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7f9;
  border-radius: 1rem;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.inputarea {
  flex-basis: 85%;
}

.input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  background-color: #f5f7f9;
  border-radius: 1rem;
  color: #4f5386;
  cursor: pointer;
  border: none;
}

.logoarea {
  display: flex;
  cursor: pointer;

}

.closelogo {
  display: flex;
  align-items: left;
  justify-content: left;
  padding-right: 50px;
  cursor: pointer;
  /* background-color: yellow; */
}

.inlinecontainer {
  cursor: pointer;
  position: absolute;
  z-index: 5;
}