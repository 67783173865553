.table {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
    height: 100%;
}

.firsthead {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.lasthead {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.headcell {
    background-color: #f5f7f9;
    color: #4f5386;
    text-align: left;
    height: 35px;
    padding-left: 20px;
    /* font-family: 'Metropolis-SemiBold'; */
    font-size:.8rem;
}

.head {
    text-align: left;
    border-top: 1px solid #edeef3;
    border-bottom: 1px solid #edeef3;
    height: 28px;

}

