.container {
    margin: 15px;
    padding: 15px 10px;
    border-radius: 12px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    background-color: #fff;
}

.button {
    display: flex;
    justify-content: center;
    background-image: none;
    background-color: #fff;
}