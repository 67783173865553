.listitem {
    display: flex;
    width: 100%;
    border-bottom: solid 1px #bfc3de;
    max-height: 2rem;
    min-height: 2rem;
    padding: 25px;
    padding-left: 20px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
}

.itemicon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:white;
    border-radius: 15px;
    pointer-events:none;
    margin-right: 10px;
}

.closeicon {
    display: flex;
    flex-basis: 50%;
    justify-content: flex-end;
    padding-right: 6px;
}

.namearea {
    display: flex;
    text-align: center;
    flex-basis: 50%;
    font-family: "Metropolis-Bold" !important;
    font-size: 1rem;
    font-weight: bold;
    color: #4F5386;
}

.itemlabel {
    display: flex;
    align-items: center;
    flex: 1;
}