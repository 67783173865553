.item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90% !important;
    min-width: 48px !important;
    height: 52px !important;
    background-color: white;
    border: 1px solid #bfc3de;
    color: #505386;
    border-radius: 12px;
    box-sizing: border-box;
    flex-shrink: 0;
    row-gap: 5px;
    cursor: grab;
}

.text {
    font-size: 14px;
    font-family: 'Metropolis-Bold';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.2;
}

.rate {
    font-size: 10px;
}

.clicked {
    background-image: linear-gradient(to left, #6608b7 100%, #000d89 0%);
    color: white;
}