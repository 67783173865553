.container {
  width: 100%;
}

.chipContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 2px 8px;
  border: 1px solid #bfc3de;
  border-radius: 10px;
  background-color: white;
  min-height: 3rem;
  align-items: center;
}

.chip {
  display: flex;
  align-items: center;
  background-color: #e8eaf6;
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 14px;
  color: #4f5386;
}

.removeChip {
  border: none;
  background: none;
  color: #4f5386;
  margin-left: 4px;
  cursor: pointer;
  font-size: 16px;
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeChip:hover {
  color: #f44336;
}

.chipInput {
  border: none !important;
  padding: 4px !important;
  flex: 1;
  min-width: 120px;
}

.chipInput:focus {
  outline: none;
}
