.switcharea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.form {
  width: 100%;
  height: 100%;
}

.linkform {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.creditcardsection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  row-gap: 10px;
}

.linksection {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: 10px;
}

.customerarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  row-gap: 10px;
}

.inputgroup {
  display: flex;
  justify-content: space-between;
  flex-basis: 30%;
  column-gap: 10px;
}

.groupitem {
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  flex-basis: 48%;
}

.transactionarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  row-gap: 10px;
}

.installmentarea {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
  row-gap: 10px;
}


.descriptionsubmit {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  row-gap: 10px;
}

.top {
  display: flex;
  justify-content: space-between;
  flex-basis: 48%;
}

.inputarea {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 48%;
  height: 100%;
  row-gap: 10px;
}

.triplearea {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}

.infoarea {
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 49%;
}

.bottomcontent {
  justify-content: space-between;
  display: flex;
  flex-basis: 75%;
}

.info {
  display: flex;
  justify-content: space-between;
  height: 3rem;
  column-gap: 10px;
  width: 100%;
}

.infolabel {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 80%;
}

.pricelabel {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.primary {
  color: #000d89;
  font-size: .8rem;
}

.subheaders {
  font-weight: bold;
  color: #000d89;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.bannerarea {
  display: flex;
  justify-content: center;
  flex-basis: 15%;
}

.banklist {
  display: flex;
  width: 100%;
  padding-top: 1rem;
}

.chooselabel {
  margin-top: 5px;
  font-size: 11px;
}

.error {
  margin-top: 5px;
  color: red;
  font-size: 11px;
}

/* For Responsive -------------------------------------------------------------------------------------------------------------------------------- */

.linkformresponsive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  row-gap: 10px;

}

.inputfieldresponsive {
  display: flex;
  width: 100%;
  align-self: center;
}

.subheaderresponsive {
  display: block;
  width: 400px;
}

.subtitle {
  align-self: center;
  color: #4f5386;
  font-family: "Metropolis";
  margin: 10px 33px 15px 20px;
  font-size: 12px;
}

.subtitle2 {
  align-self: center;
  color: #4f5386;
  font-family: "Metropolis";
  margin: -250px 33px 15px 20px;
  font-size: 12px;
}

.infolabelresponsive {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 170px;
  color: #4f5386;
  font-family: "Metropolis";
  font-weight: 500;
  font-size: 15px;

}