  
  .checkcontainer {
    display: flex;
    align-items: center;
  }
  
  .checkcontainer input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .checkcontainer label {
    position: relative;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .checkcontainer .label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #bfc3de;
    border-radius : 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .checkcontainer input:checked + .label:after {
    content: '';
    display: block;
    position: absolute;
    top: 16%;
    left: 8px;
    width: 4px;
    height: 0.625rem;
    border: solid  
    #000d89;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .disabled{
    display: none;
  }