.container{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.filtercontainer{
    background-color: #ffffff;
    border-bottom: 3px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

.switchbutton{
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.infoarea{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 30px 15px 15px 15px;
}

.userinfo{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.normallabel{
    display: flex;
    flex-direction: column;
    row-gap:55px;
    font-size: 1rem;
    color: #4f5386;
}


.companyinformation{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    color: #000d89;
    background-color: #f5f7f9;
    row-gap: 5px;
    padding-top: 15px;
}

.undertable {
    flex-grow: 0;
    margin: 15px 15px 20px;
    padding: 0 0 15px;
    border-radius: 12px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    background-color: #fff;
}

.uprow{
    flex-grow: 0;
    margin: 0 0 15px;
    padding: 15px 84px 15px 15px;
    background-color: rgba(237, 238, 243, 0.5);
}

.underrow{
    margin: 0 0 15px;
    padding: 0 15px 0 15px;
}


.instalmentnumber{
    flex-grow: 0;
    padding: 9px 15px;
    background-color: #f5f7f9; 
}

.commissionrate{
    flex-grow: 0;
    padding: 9px 15px;
    background-color: #ffffff; 
}

.bannerarea {
    height: 500px;
    flex-grow: 0;
    margin: 20px 15px 0;
    border-radius: 12px; 
}

.underrowsecond{
    margin: 0 0 15px;
}

.uprowsecond{
    flex-grow: 0;
    margin: 0 0 5px;
    padding: 15px 84px 15px 15px;
    background-color: rgba(237, 238, 243, 0.5);
}
