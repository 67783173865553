.dropdown {
    display: flex;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
    padding: .7rem;
    font-family: 'Metropolis';
    color: #4f5386;
    border-radius: 10px;
    background-color: white;
    border: solid 1px #bfc3de;
}

.dropdownarea {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    pointer-events: none;
}

.iconbutton {
    background-color: transparent;
    border: none;
}

.iconbutton {
    background-color: transparent;
    border: none;
}

.dropdowncontent {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: solid 1px #bfc3de;
    background-color: #fff;
    border-radius: 1rem;
    overflow-y: overlay;
    max-height: 10rem;
}


.dropdowncontent::-webkit-scrollbar {
    width: 5px;
    height: 5px;

}

.dropdowncontent::-webkit-scrollbar-thumb {
    background-color: #c3c7df;
    border-radius: 10rem;

}

.dropdowncontent::-webkit-scrollbar-track {
    margin: 10px;
}

.separator {
    border: solid 1px #bfc3de;
    border-left: 0px;
    border-right: 0px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    background-color: #fff;
    background-color: yellow;
}

.filterInput {
    width: 100%;
    height: 3rem;
    border-radius: 1rem;
}

.notfound {
    padding: 1rem;
    font-size: 1rem;
    color: #4f5386;
}