.bankcontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px auto;
    padding: 10px;
}
.bankcontainer li{
    display: inline;
    padding: 20px;
}
.bankcontainer ul{
    text-align: center;
    list-style:none outside none;
    margin: 0px 0px 0px -65px;    
}