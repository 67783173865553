.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.filtercontainer {
    background-color: #ffffff;
    border-bottom: 3px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

.filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modalform {
    display: flex;
    flex-direction: column;
    background-color: #edeef3;
    justify-content: center;
    align-items: center;
    padding: 10%;
    width: 90%;
    height: 50%;
    row-gap: 10px;
}

.formitems {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px;
    height: 80%;
}

.forminfo {
    display: flex;
    width: 100%;
    font-size: 1.7rem;
    font-weight: bold;
    color: #000d89;
    margin-bottom: 20px;
}

.form {
    width: 100%;
    height: 100%;
}