.collapsecontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.row2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.col1 {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    margin-right: auto;
    row-gap: 5px;
}

.col2 {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    margin-right: auto;
    row-gap: 5px;
}

.rowtexts {
    height: 10px;
    margin: 10px 0 5px;
    font-family: "Metropolis";
    font-size: 10px;
    color: #4f5386;
}

.datas {
    height: 12px;
    margin: 0 82px 0 0;
    font-family: "Metropolis";
    font-weight: bold;
    font-size: 12px;
    color: #4f5386;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;

}
.details{
    margin: 10px;
}