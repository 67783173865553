.filtercontainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.firstarea {
    display: flex;
    width: 30%;
    justify-content: space-between;
    align-items: center;
}

.searcharea {
    z-index: 15;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: 'auto';
    background-color: white;
    border-radius: 10px;
    border: solid 1px #bfc3de;
    padding: .5rem;
    box-sizing: border-box;
}


.lastarea {
    display: flex;
    width: 310px;
    justify-content: space-between;
    align-items: center;
}