.searcharea {
    display: flex;
    justify-content: space-between;
    width: 40%;
}


.biglabel {
    margin: 0 47px 5px 0;
    font-size: 1.8rem;
    color: #000d89;
    font-weight: 600;
}

.normallabel {
    flex-grow: 0;
    margin: 1px 5px 10px 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4f5386;
}

.chartcontainer {
    display: flex;
    width: 97%;
    justify-content: center;
    align-items: center;
}

.info {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 10px;
}