.filterarea{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 2%;
    padding: 20px 0;
    min-height: 30px;
}

.filtercontainer{
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9dbee;
    background-color: white;
}

.customcontainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 100%;
}

.searchsection{
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-basis: 30%;
}

.first{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
}

.second{
    /* margin-top: 1.1rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
}
.filtergroup{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis:100%;
    column-gap: 20px;
}

.buttongroup{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 20%;
}
.switchitem{
    width:30%;
    margin-left: auto;
}

.disabled{
    display: none;
}