
.searchareafilter{
  display : flex;
  justify-content: flex-end;
  align-items: center;
  width:100%;
  height: 15px;
}



.formitems{
  display:flex;
  flex-direction:column;
  /* justify-content :space-between; */
  row-gap: 20px;
  /* height: 80%; */
}

.forminfo{
  display:flex;
  width: 100%;
  font-size: 1.7rem;
  font-weight: bold;
  color: #000d89;
  margin-bottom: 20px;
}
.form{
  width: 100%;
  /* height: 100%; */
}
.inputgroup{
  display : flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
.listarea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
  border: solid 1px #edeef3;
  background-color: #fff;
}
.support {
  height: 38rem;
  border-radius: 2rem;
  text-align: center;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.list {
  width: 70%;
  border-radius: 16px;
  text-align: center;
  margin-left: 2rem;
  border: 1px solid;
}





