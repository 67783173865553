.formitems{
    display:flex;
    flex-direction:column;
    justify-content :space-between;
    height: 100%;
    width: 100%;
    margin: 1rem;
}

.forminfo{
    display:flex;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Metropolis-Bold' !important;
    color: #000d89;
    margin-bottom: 20px;
}

.inputgroup{
    display : flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    row-gap: 10px;
}

.form{
    width: 100%;
    height: 100%;
    
}

