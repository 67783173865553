.sharecontainer{
    display:flex;
    justify-content:space-between;
    box-sizing: border-box;
    align-items: center;
    font-size: 1rem;
    border-radius: 10px;
    background-color: white;
    border: solid 1px #bfc3de;
    padding: 1rem;

}

.copy{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    min-width: 40px;
}

.alert{
    font-size: 10px;
}

.input{
    width:80%;
    background-color: white;
    border:none;
    color: #000d89;
    font-weight: bold;

}