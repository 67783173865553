.isactive {
    position: fixed;
    z-index: -1;
}

.general{
    width: 100%;    
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
}

.col{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0.8rem 0;
}

.row > div:first-child {
    min-width: 160px;
    flex-shrink: 0;
}

.row > div:last-child {
    min-width: 160px;
}

.label{
    color: #4f5386;
    font-size: 1rem;
    font-family: 'Metropolis-SemiBold';
    font-weight: 500;
}

.semiBold {
    font-family: 'Metropolis-SemiBold'; 
    font-weight: 600;
    color: #000d89;
}

.general {
    padding: 30px 30px 30px 30px;
    width: 100%;
    display: block;
}

.logo {
    height: 5rem;
}

.container {
    display: flex;
    flex-direction: row;
    padding: 2rem;
    border-radius: 1rem;
    border: 1px solid #919eba;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
}

.leftside {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 50%; 
}

.rightside {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 50%; 
}

.sectionHeader {
    font-family: 'Metropolis-Bold'; 
    font-weight: bold;
    color: #000d89;
    font-size: 1.2rem;
}

.sectionDivider {
    border-bottom: 2px dotted #919eba;
    margin: 1.5rem 0;
    width: 100%;
}

.verticalDivider{
    border-left: 2px dotted #919eba;
    margin: 0 3rem;
}

.rightside {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}