.container {
    height: 100%;
}

.navbar {
    /* background-color: red; */
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-bottom: solid 3px transparent;
    background-image: linear-gradient(to right, #01effd 0%, #010f8a 51%, #f601fb 100%);
    background-origin: border-box;
    background-clip: border-box;
    box-shadow: 2px 1000px 1px #fff inset;

}