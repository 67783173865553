.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.formitems {
    display: flex;
    flex-direction: column;
    padding: 15px;
    row-gap: 10px;
}

.container{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.filtercontainer{
    background-color: #ffffff;
    border-bottom: 3px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

.switchbutton{
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.biglabel {
    margin: 0 47px 5px 15px;
    font-size: 1.2rem;
    color: #000d89;
    font-weight: 600;
}

.table {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.tablehead{
    height: 20px;
    margin: 15px;
    padding: 15px 10px;
    border-radius: 12px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    background-color: #f5f7f9;
    color: #4f5386;
    display: flex;
}

.tableitem{
    height: 25px;
    margin: 15px;
    padding: 15px 10px;
    border-radius: 12px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    background-color: #fff;
    color: #4f5386;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.spacer{
    width: 33%;
    display: flex;
    justify-content: space-around;
}