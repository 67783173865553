.filter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.modalinside {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f7f9;
    height: 60%;
    width: 90%;
    padding: 20px 10% 
}

.modalinsideUpdate{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f7f9;
    height: 40%;
    width: 90%;
    padding: 20px 10% 
}