.triplecontainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    height: 30px;
    border-radius: 10px;
    border: solid 1px #d4ddf0;
}

.section{
    flex-grow: 1;
    height: 100%;
}

.button{
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    cursor: pointer;
}

.bottom{
    /* border-right:  solid 1px #d4ddf0;
    border-left:  solid 1px #d4ddf0; */
}

.text2{ 
    color: #4f5386;
    font-size: 0.9rem;
}
