.formitems {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem;
}

.forminfo {
    display: flex;
    width: 100%;
    font-size: 1.7rem;
    font-weight: bold;
    color: #000d89;
}

.inputgroup {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.inputarea {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    row-gap: 10px;
    margin-top: 20px;
}

.form {
    width: 100%;
    height: 100%;
    margin-top: 20px;
}

.switcharea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
}


.infoheader{
    margin-right: auto;
    font-size: 1.5rem;
    font-family: 'Metropolis-Bold';
    font-weight: 700;
    margin-bottom: 20px;
}