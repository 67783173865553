.image {
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 16px;
    font-size: 20px;
    color: white;
    align-items: center;
    justify-content: center;
    /* max-height: 250px; */
}