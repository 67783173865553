.section {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 1%;
    margin-bottom: 1%;
    min-height: 17rem;
    width: 30%;
    row-gap: 20px;
}

.sectionheader {
    color: #4f5386;
    font-size: 1.1rem;
    font-family: 'Metropolis-Bold';
}

.item {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    height: 30%;
    padding: 15px 0;
}

.sectionitem {
    margin-top: 2%;
}

.twicerow {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.twiceleft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
}


.twiceright {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 30%;
    row-gap: 8px;
}

.leftside {
    display: flex;
    width: 50%;
    flex-direction: row;
}

.leftsideleft {
    width: 60%;
}

.rightside {
    display: flex;
    flex-direction: column;
}

.rightsidetop {
    display: flex;
    flex-direction: row;
}

.cardlogo {
    width: 64px;
    height: 45.7px;
}

.verticalseparator {
    margin-top: 1%;
    margin-bottom: 1%;
    width: 0px;
    border-left: 1px dashed #919eba;
    height: 90%;
}

.sectioncontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 100%;
}

.lastsection {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 30%;
    row-gap: 20px;
}

.test {
    display: flex;
    width: 100%;
}


.borderLine {
    display: flex;
    flex-direction: row;
    width: 690px;
    height: 295px;
    margin: 20px 0 15px;
    padding: 20px;
    border-radius: 8px;
    border: solid 0.2px #919eba;
    background-color: rgba(255, 255, 255, 0.5);
}


.vertical {
    margin: 0 0 30px 300px;
    padding: 0 0 0 0;
    transform: rotate(-180deg);
    width: 0px;
    height: 100%;
    color: #919eba;
}


.horizontal {
    margin: 150px -95px 0 150px;
    transform: rotate(270deg);
    width: 0px;
    height: 100%;
    color: #919eba;
}

.transaction {
    height: 9px;
    flex-grow: 0;
    font-family: Metropolis;
    font-size: 9px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4f5386;
}

.creditCard {
    width: 64px;
    height: 45.7px;
    background-color: #1ebcc8;
}

.transactionInformation {
    width: 73px;
    height: 11px;
    flex-grow: 0;
    margin: 0 202.7px 9.8px 0.3px;
    font-family: Metropolis;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4f5386;
}

.customerInformation {
    width: 85px;
    height: 11px;
    font-family: Metropolis-bold;
    font-size: 11px;
    font-weight: bold;
    text-align: left;
    color: #4f5386;
}

.transactionValue {
    height: 7px;
    font-family: Metropolis;
    font-size: 10px;
    color: #4f5386;
}

.values {
    height: 9px;
    font-family: Metropolis-bold;
    font-size: 9px;
    text-align: left;
    color: #4f5386;
}

.valuesleft {
    display: flex;
    flex-direction: column;
}

.adress {
    margin: 15px 0 0 0;
    font-family: Metropolis;
    font-size: 8px;
    text-align: left;
    color: #4f5386;
}