.scrollablehorizontalitems {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    padding: 20px 5px;
}

.scrollablehorizontalitems::-webkit-scrollbar {
    background-color: transparent;
    height: 10px;
}
.scrollablehorizontalitems::-webkit-scrollbar-thumb {
    display: none;
}

.hidden{
    display: none;
}

.rightarrow{
    position: absolute;
    z-index: 1;
    padding: 5px;
    right: 0;
    top: 38%;
    background-color: white;
    border: 1px solid #bfc3de;
    border-radius: 50%;
    align-items: center;
    cursor: pointer;
}

.tablehead{
    float:left;
    flex-direction: column;
    border-bottom: solid 1px #edeef3;

}
.uphead{
    flex-grow: 0;
    margin: 1 7px 0 0;
    padding: 8px;
    padding-right: 83px;
    background-color: #f5f7f9;
    display: flex;
    border-right: solid 1px #edeef3;
    border-bottom: solid 1px #edeef3;

}

.underhead{
    flex-grow: 0;
    margin: 1 7px 0 0;
    padding: 8px;
    padding-right: 83px;
    background-color: #fff;
    border-right: solid 1px #edeef3;
    border-bottom: solid 1px #edeef3;

}

.text{
    background-color: #f5f7f9;
    padding: 8px;
    border-bottom: solid 1px #edeef3;

}

.rate{
    background-color: #fff;
    padding: 8px;
    border-bottom: solid 1px #edeef3;

}
