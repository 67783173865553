.collapsecontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2%;
}

.sendeddescription {
    margin-bottom: 20px;
    width: 400px;
    display: flex;
    justify-content: space-between;
}

.bottomicons {
    padding: 10px;
}

.text {
    margin-bottom: 10px;
    margin-top: 10px;
    color: #4f5386;
    font-size: 0.8rem;
    width: 145px;
}

.data {
    font-family: "Metropolis";
    font-weight: bold;
    color: #4f5386;
    font-size: 0.8rem;
    padding-right: 80px;
}

.header {
    margin-bottom: 10px;
    margin-top: 10px;
    color: #4f5386;
}

.secondcolumn {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.triple {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 10px;
}