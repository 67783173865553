.filter {
    float: left;
     width: 90vw; /*Soooooo important */
}

.inputarea {
    margin-left: 10px;
}

.multiplebutton {
    display: flex;
    margin-left: 10px;
}

.form {
    display: flex;
    align-items: center;
    padding-top: 10px;
    width: 100%;
    row-gap: 5px;
    flex-direction: column;
    background-color: white;
}

.sticky {

    align-items: center;
    justify-content: space-between;
    bottom: 20px;
    width: 70%;
    margin-left: 15%;
    min-height: 78px;
    border-radius: 16px;
    background-image: linear-gradient(to right, #6608b7 0%, #5f00b3 100%);
    align-self: center;
    position: sticky;
}