.searchItem{
    margin-left: 1rem;
    display : flex;
}
.searchArea {
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    margin: 20px;
    height: 5rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
.infoCard{
    display : flex;
    border: 1px solid;
    border-radius: 10px;
    height: 400px;
    margin: 20px;
    margin-left: 400px;
    align-items: center;
    justify-content: space-around;
}
.infoLabel{
    flex-shrink: 1;
    margin-right: 10rem;
    font-size: 1.3rem;
}
.infoheader{
    margin: 2%;
    color: #4f5386;
    display: flex;
    font-size: 1.3rem;
    line-height: 1.43;
    flex-direction: column;
    justify-content: center;
}
.margintop{
    margin-top: 2%;
}
.header{
    font-size: 1.5rem;
    font-weight: bold;
    color: #000d89;
    margin-top: 3%;
}
.normallabel{
    display: flex;
    flex-direction: column;
    row-gap:5px;
    font-size: 1.7rem;
    color: #4f5386;
}

.leftside{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    flex-basis: 36%;
    border-radius: 16px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    background-color: #fff;
}

.rightside{
    display: flex;
    flex-direction: column;
    flex-basis: 60%;
    row-gap: 20px;
}

.righttable{
    display: flex;
    flex-basis: 30%;
    border-radius: 16px;
    box-shadow: 0 9px 25px 0 rgba(219, 224, 230, 0.67);
    padding:1rem;
    background-color: #fff;
}

.bannerarea{
    display: flex;
    background-color:gray;
    color:white;
    justify-content:center;
    align-items:center;
    font-size: 1.3rem;
    flex-basis: 30%;
    margin-top: 3%;
    border-radius: 16px;
}