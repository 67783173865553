.scrollablehorizontalitems {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    padding: 20px 5px;
}

.scrollablehorizontalitems::-webkit-scrollbar {
    background-color: transparent;
    height: 10px;
}
.scrollablehorizontalitems::-webkit-scrollbar-thumb {
    display: none;
}

.hidden{
    display: none;
}

.rightarrow{
    position: absolute;
    z-index: 1;
    padding: 5px;
    right: 0;
    top: 30%;
    background-color: white;
    border: 1px solid #bfc3de;
    border-radius: 50%;
    align-items: center;
    cursor: pointer;
}

.leftarrow{
    position: absolute;
    z-index: 1;
    padding: 5px;
    left: -10px;
    top: 30%;
    background-color: white;
    border: 1px solid #bfc3de;
    border-radius: 50%;
    cursor: pointer;
}