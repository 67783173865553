.listitem{
    display : flex;
    width: 100%;
    margin-bottom: 5px;
    border-radius: 10px;
    border: solid 1px #bfc3de;
    max-height: 4rem;
    min-height: 3rem;
}

.listitem:hover{
    background-color: rgb(219, 219, 219);
    cursor: pointer;
    color: white !important;
}

.itemicon{
 display: flex;
 align-items:center;
 justify-content:center;
 flex-basis: 10%;
 background-color: #f5f7f9;
 margin:5px;
 border-radius: 10px;
}

.itemlabel{
    display: flex;
    align-items:center;
    flex-basis: 90%;
    color: #4f5386;
    font-size: 1rem;
    font-weight: lighter;
}