.text {
    background-color: #f5f7f9;
}

.slick-list {
    width: 100% !important;
}


.scrollablehorizontalitems {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    padding: 20px 5px;
}

.scrollablehorizontalitems::-webkit-scrollbar {
    background-color: transparent;
    height: 10px;
}

.scrollablehorizontalitems::-webkit-scrollbar-thumb {
    display: none;
}

.hidden {
    display: none;
}