.collapsecontainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2%;
}

.headerandvalue{
    display: flex;
    flex-direction: column;
    margin: 5px;
    font-size: 12px;
    color: #4f5386;

}

.header{
    font-size: 10px;
    color: #4f5386;
}

.value{
    height: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.item{
    font-family: Metropolis;
    font-weight: bold;
    color: #4f5386;
    height: 12px;
}

.detail{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;

}