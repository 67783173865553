.menucontainer {
  display: flex;
  height: 8vh;
  justify-content: space-around;
  align-items: center;
  background-color: white;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  width: 95%;
}

.logoarea {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 15%;
}

.logoname {
  display: flex;
  justify-content: center;
  align-items: center;
}


.menuitem {
  display: flex;
  text-align: center;
  flex-direction: row;
  text-decoration: none;
  color: #4f5386;
  font-family: 'Metropolis-SemiBold';
  size: .9rem;
}

.supportarea {
  display: flex;
  text-align: center;
  flex-direction: column;
  text-decoration: none;
  color: #4f5386;
  font-family: 'Metropolis-SemiBold';
  size: .9rem;
  padding-left: 0.2rem;
  width: 77px;
}

svg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  height: 0.4rem;
  flex-grow: 0;
  background-image: linear-gradient(to right, #01effd 0%, #010f8a 51%, #f601fb 100%);
}

.container {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100vh;
  margin: 0 2%;
}

.menuarea {
  display: flex;
  width: 80%;
}

.item {
  margin-left: 50px;
  text-decoration: none;
  color: #4f5386;
  font-family: 'Metropolis-SemiBold';
  size: .9rem;
}