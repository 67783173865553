.btn {
    display: flex;
    align-items: center;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 10px;
    background-image: linear-gradient(to right, #000d89 0%, #6608b7 100%);
    cursor: pointer;
    padding: 0.8rem;
    font-family: 'Metropolis-Bold' !important;
    font-weight: 700;
}

.btncontainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

}

.icon {
    display: flex;
    flex-basis: 30%;
    justify-content: flex-end;
    pointer-events: stroke;

}

.text {
    display: flex;
    flex-basis: 70%;
    justify-content: space-around;

}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}