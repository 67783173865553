.formitems {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    width: 90%;
    margin: 1rem;
}

.formheadersection {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formheader {
    font-size: 1.5rem;
    font-family: 'Metropolis-Bold';
    font-weight: 700;
    color: #000d89;
    padding: 20px 10px 0px 10px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.forminfo {
    display: flex;
    width: 100%;
    font-size: 1.7rem;
    font-weight: bold;
    color: #000d89;
}

.inputgroup {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.inputarea {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    row-gap: 10px;
}

.form {
    width: 100%;
    height: 100%;
}

.switcharea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0px 20px 0px;
}

.headtext{
    margin-right: auto;
    font-size: 1.5rem;
    font-family: 'Metropolis-Bold';
}