.modal {
  z-index: 101;
  opacity: 0;
  visibility: hidden;
  width: 0px;
  height: 0px;
}

.active {
  opacity: 1;
  visibility: visible;
  position: absolute;
  transition: opacity 0.1s;
  color: #000;
  top: 0;
  left: 0;
}

.modalcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  height: 8vh;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.separator {
  height: 0.3rem;
  flex-grow: 0;
  background-image: linear-gradient(to right, #01effd 0%, #010f8a 51%, #f601fb 100%);
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-backdrop-filter: blur(45px);
  backdrop-filter: blur(45px);
  background-color: rgba(0, 6, 70, 0.66);
  width: 100%;
  height: 100%;
  color: white;
}