

.btndecoration{
    display : flex;
    align-items: center;
    text-decoration: none;
    list-style:none;
}
.searcharea{
    display : flex;
    justify-content:space-between;
    align-items: center;
    column-gap: 10px;
}

.multiselectarea{
    display: flex;
    align-items:center;
    column-gap: 10px;
}

.multiselect{
    display: flex;
}
.paginationsection{
    display : flex;
    justify-content: flex-end;
    align-items: center;
}
.searchinfo{
    font-size: .9rem;
    color: #4f5386;
    flex-basis: 50%;
    font-family: 'Metropolis-Light';
    min-width: 200px;
}

.selectgroup{
    display : flex;
    justify-content: space-between;
    margin-left: 10px;
    border: solid .1rem #d4ddf0;
    border-radius: 0.5rem;
    height: 2rem;
    min-width: 70px;
    max-width: 70px;
}

.pagination{
    display : flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
}

.navigator{
    display : flex;
    justify-content: space-around;
    flex-basis: 70%;
}

.btnmove{
    display : flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: solid 0.1rem #d4ddf0;
    background-color: white;
    border-radius: 0.5rem;
    height: 0.1rem;
    width: 0.1rem;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    color: #000d89;
}




.btncount{
    display : flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    background-color: white;
    border:none;
    color: #000d89;
    padding: 5px;
}

.countselected{
    font-weight: bold;
}


.inputgroup{
    display:flex;
    border: solid .1rem transparent;
    border-radius: 0.5rem;
 }

.input{
    display : block;
    border: none;
    background-color: inherit;
    color: #000d89;
    font-weight: bold;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}



.counterbtngroup{
    display:flex;
    flex-direction: column;
    justify-content:center;
    border-left: solid .1rem #d4ddf0;
}

.upbtn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border:none;
    cursor: pointer;
    color: #000d89;
    font-weight: bold;
}

.downbtn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border:none;
    cursor: pointer;
    color: #000d89;
    font-weight: bold;
}