.datelabel{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.time{
font-family: 'Metropolis-Bold';
font-size:.9rem;
color: 
#4f5386;
}

.date{
    font-family: 'Metropolis';
    font-size:.9rem;
}