.formitems{
    display:flex;
    flex-direction:column;
    justify-content :space-between;
    height: 100%;
    width: 100%;
    margin: 1rem;
    row-gap: 20px;
}

.forminfo{
    display:flex;
    width: 100%;
    font-size: 1.7rem;
    font-weight: bold;
    color: #000d89;
}

.inputgroup{
    display : flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    row-gap: 10px;
}

.form{
    width: 100%;
    height: 100%;
    
}