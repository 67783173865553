.switcher {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: solid 1px #bfc3de;
    border-radius: .8rem;
    height: 2.4rem;
}

.leftswitchDealer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 33.33%;
    height: 100%;
    border-bottom-left-radius: 0.8rem;
    border-top-left-radius: 0.8rem;
    color: #4f5386;
}



.middleswitchDealer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 33.33%;
    height: 100%;
    color: #4f5386;
}

.linkicon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: none;

}

.icon {
    margin-right: 5px;
}

.cardicon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: none;

}


.rightswitchDealer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 33.33%;
    height: 100%;
    border-bottom-right-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    color: #4f5386;
}


.leftswitch {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
    height: 100%;
    border-bottom-left-radius: .8rem;
    border-top-left-radius: .8rem;
    color: #4f5386;
}

.smalltext {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 10px;
    margin-top: 8px;
    pointer-events: none;

}

.rightswitch {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
    height: 100%;
    border-bottom-right-radius: .8rem;
    border-top-right-radius: .8rem;
    color: #4f5386;
}

.active {
    background-image: linear-gradient(to right, #000d89 0%, #6608b7 100%);
    color: white !important;
}

.switchitem {
    display: block;
    text-decoration: none;
    width: 100%;
    text-align: center;
    color: inherit;
    cursor: pointer;
}