.filterbtn{
    display:flex;
    align-items: center;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
    border: solid 1px #bfc3de;
    width: 150px;
    font-size: 0.92rem;
}

.btncontainer{
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-grow: 1;
    padding: 0.8rem;

}

.infotext{
    color: #4f5386;
    font-size: 0.875rem;
}

.infocount{
    font-weight: bold;
    color: #4f5386;
    font-size: 1.25rem;
    padding-left: 10px;
}