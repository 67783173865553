.item {
    display: flex;
    align-items: center;
    padding: 1.3vh 0 1.3vh 1.3vh;
    cursor: pointer;
    padding-left: 2.5vh;
    /* border-bottom: solid 1px #bfc3de; */
}

.separator {
    border: solid 1px #bfc3de;
    border-left: 0px;
    border-right: 0px;
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.clicked {
    background-image: linear-gradient(to right, #020e8a 0%, #6608b7 100%);
    color: white;
}

.first {
    border-top-left-radius: 1rem;
    border-top: 0px !important;
}

.last {
    border-bottom-left-radius: 1rem;
}