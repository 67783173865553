.headinfo{
    display : flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    padding: 20px;
}

.headcontainer{
    display : flex;
    justify-content: space-between;
    align-items: center;
    width:90%;
}