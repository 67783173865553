.searcharea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    height: 15px;
}


.biglabel {
    margin: 0 47px 5px 0;
    font-size: 1.8rem;
    color: #000d89;
    font-weight: 600;
}

.normallabel {
    font-size: 1.2rem;
    margin: 1px 5px 10px 0;
    color: #4f5386;
}

.chartcontainer {
    display: flex;
    width: 97%;
    justify-content: center;
    align-items: center;
}

.info {
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
    margin-bottom: 10px;
}